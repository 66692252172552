<template>
  <!-- <div> -->
  <v-row>
    <v-col cols="12" sm="3" md="3">
      <v-btn elevation="2" x-large block>
        <v-text-field
          class="mt-5"
          :label="config.label"
          v-model="button.text"
        ></v-text-field>
      </v-btn>
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <v-text-field label="URL" v-model="button.url"></v-text-field>
    </v-col>
    <v-col cols="12" sm="3" md="3">
      <v-checkbox
        v-model="button.target"
        label="Új lapon nyíljon"
        value="_blank"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ButtonCustomFieldComponent",
  props: ["config", "button"],
  data() {
    return {};
  },

  watch: {
    button() {
      this.handleChangeValue();
    },
  },

  methods: {
    handleChangeValue() {
      this.$emit("handleChangeValue", this.button);
    },
  },
};
</script>
